import logo from "./logo.png";
import img1 from "./industrial-1636380_1280.jpg";
import img2 from "./cleanliness-2799475_1280.jpg";
import img3 from "./woman-5928694_1280.jpg";

import "./App.css";
import {
  BsGraphUpArrow,
  BsLightningCharge,
  BsPersonCircle,
  BsPersonVcard,
  BsTelephone,
} from "react-icons/bs";

function App() {
  return (
    <>
      <header>
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-2">
          <div className="sm:flex sm:items-center sm:justify-between">
            <a
              href="/"
              className="flex items-center justify-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-16" alt="Flowbite Logo" />
            </a>
            <ul className="flex flex-wrap items-center justify-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  Licensing
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <section class="h-96 bg-center bg-cover bg-no-repeat bg-[url('hero.jpg')] bg-gray-300 bg-blend-multiply"></section>

      <div className="bg-black text-center text-white py-5  text-4xl">
        <p>Cleaning & Janitorial</p>
        <p>Franchise Opportunities</p>
      </div>

      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8 flex flex-wrap space-x-0 lg:flex-nowrap lg:space-x-8">
        <section>
          <div className="mx-auto py-8">
            <div className="flex lg:flex-nowrap flex-wrap space-x-0  w-full lg:space-x-7">
              <div className="w-full lg:w-1/4">
                <img src={img1} />
              </div>
              <div className="lg:w-3/4 space-y-4">
                <p className="text-4xl">
                  Why <span className="text-cyan-500">Franchise?</span>
                </p>
                <p>
                  Franchising is on the rise with the surge of people wishing to
                  go into business for themselves. It is a perfect fit for
                  anyone looking to run a successful business while having the
                  support of a proven and established franchise system.
                </p>

                <p>
                  Owning your own business will always come with risk but with
                  the right approach and the right backing provided by a
                  franchise business model and Master Franchise regional support
                  center, your small business’s chances for success are greatly
                  enhanced. A franchise provides you with the management
                  expertise, sales models, marketing support, and the
                  opportunity to grow!
                </p>

                <p>
                  Join the thousands of individuals discovering the benefits are
                  owning your own small business with the support of a proven
                  business model.
                </p>
              </div>
            </div>
          </div>

          <div className=" mx-auto py-8">
            <div className="flex space-x-10">
              <div className="space-y-4">
                <p className="text-4xl">
                  Why{" "}
                  <span className="text-cyan-500">
                    Commercial Cleaning Franchise?
                  </span>
                </p>
                <p>
                  The commercial cleaning industry is one of the fastest-growing
                  services in the world today. The janitorial services industry
                  is currently worth 63 billion-dollars according to the IBIS
                  World Industry Report and is projected to continue to grow as
                  cleaning services become a necessity during and post-pandemic.
                  A commercial cleaning franchise will continue to provide
                  tremendous opportunity. Everywhere you look, there are new
                  buildings being built, and businesses being opened and all of
                  these businesses need cleaning services.
                </p>

                <p>
                  Frequent customers are schools, medical offices, shopping
                  centers, warehouses, dealerships, religious centers,
                  professional offices, daycares, retail stores, gyms, and more.
                  Someone has to clean them all. Purchasing a commercial
                  cleaning franchise will provide a limitless source of new
                  customers, from major metropolitan areas to smaller towns. The
                  possibilities for revenue are endless.
                </p>

                <p>
                  The commercial cleaning industry is labeled by many economists
                  as a recession-proof industry. It is distinctive and proven in
                  providing a continuous income even during an economic
                  downturn. Businesses will always need to be cleaned, no matter
                  how the economy changes.
                </p>
              </div>
            </div>
          </div>

          <div className="  py-8">
            <div className="flex">
              <div className="space-y-4 w-full">
                <hr className="border-gray-300" />
                <div className="flex flex-wrap items-center justify-items-end">
                  <div className="w-full lg:w-3/4">
                    <p className="font-light text-center py-4">
                      <span className="text-3xl">
                        Find Your Local Franchising Office
                      </span>{" "}
                      <br />
                      <span className="text-2xl">
                        Request More Information <b>Today!</b>
                      </span>
                    </p>
                  </div>
                  <div className="w-full lg:w-1/4 flex justify-center">
                    <button className="bg-cyan-600  p-3 text-white text-lg rounded-md text-center">
                      Find A Location
                    </button>
                  </div>
                </div>

                <hr className="border-gray-300" />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap py-5 space-y-10 lg:space-y-0 space-x-7 mb-5">
            <div className="flex flex-col justify-center items-center space-y-2 w-full">
              <div className="text-4xl text-cyan-500">
                <BsGraphUpArrow />
              </div>
              <p className="text-center text-3xl"></p>
              <p className="text-2xl text-center">Demand For Services</p>
              <p className="text-center">
                Cleaning is now a necessity, be where the need is.
              </p>
            </div>

            <div className="flex flex-col justify-center items-center space-y-2 w-full">
              <div className="text-4xl text-cyan-500">
                <BsPersonVcard />
              </div>
              <p className="text-center text-3xl"></p>
              <p className="text-2xl text-center">Guaranteed Accounts</p>
              <p className="text-center">
                Be in business for yourself, not by yourself.
              </p>
            </div>

            <div className="flex flex-col justify-center items-center space-y-2 w-full">
              <div className="text-4xl text-cyan-500">
                <BsLightningCharge />
              </div>
              <p className="text-center text-3xl"></p>
              <p className="text-2xl text-center">Cleaning Technology</p>
              <p className="text-center">
                On the cutting edge of cleaning tools and processes.
              </p>
            </div>

            <div className="flex flex-col justify-center items-center space-y-2 w-full">
              <div className="text-4xl text-cyan-500">
                <BsPersonCircle />
              </div>
              <p className="text-center text-3xl"></p>
              <p className="text-2xl text-center">Regional Support</p>
              <p className="text-center">
                The expert assitance you need to be successful.
              </p>
            </div>
          </div>
        </section>

        <div className="w-full">
          <div className="p-5 bg-black rounded-lg w-full">
            <p className="text-2xl text-center text-white">
              Request More Information <br />
              <span className="font-bold">Today!</span>
            </p>
            <div className="flex items-center text-center mt-5 space-x-1 justify-center">
              <p className="text-white">
                <BsTelephone />
              </p>
              <p className="font-bold text-white text-center ">
                (904) 834-1113
              </p>
            </div>

            <div className="flex  justify-center w-full">
              <button className="bg-cyan-600  p-2  text-white text-sm rounded-md  mt-5">
                Find A Location
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-cyan-600 py-5">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="flex flex-wrap lg:flex-nowrap  justify-center space-y-5 lg:space-y-0">
            <div className="text-center w-full">
              <p className="text-4xl text-white">12000+</p>
              <p className="text-center text-white">
                Customers of Stratus Unit Franchisees
              </p>
            </div>

            <div className="text-center w-full">
              <p className="text-4xl text-white">89m+</p>
              <p className="text-center text-white">
                Sqft Cleaned on a Routine Basis
              </p>
            </div>

            <div className="text-center w-full">
              <p className="text-4xl text-white">3500+</p>
              <p className="text-center text-white">Unit Franchisees</p>
            </div>

            <div className="text-center w-full">
              <p className="text-4xl text-white">#1</p>
              <p className="text-center text-white">
                Ranked Fastest Growing Franchise
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full max-w-screen-lg mx-auto p-4 md:py-8 flex">
        <section>
          <div className=" mx-auto py-8">
            <div className="flex lg:flex-nowrap flex-wrap w-full lg:space-x-7">
              <div className="w-full lg:w-1/4">
                <img src={img2} />
              </div>
              <div className="lg:w-3/4 space-y-4">
                <p className="text-4xl">
                  Why Choose <span className="text-cyan-500">Stratus?</span>
                </p>
                <p>
                  Stratus Building Solutions has Master Franchise Offices in
                  over 57 major cities, which support more than 2,000 Unit
                  Franchisees across the US and Canada. With Stratus, you not
                  only receive prominent, brand familiarity and existing
                  credibility with customers as international, household name in
                  Janitorial Services industry; you will also be offered by the
                  regional Master Franchise Office:
                </p>
              </div>
            </div>
          </div>

          <div className="container mx-auto py-8">
            <div className="flex flex-wrap">
              <div className="space-y-4">
                <p className="text-4xl">
                  A Stratus Cleaning Franchise{" "}
                  <span className="text-cyan-500">Offers</span>
                </p>
                <ul className="list-item list-disc ml-5">
                  <li>
                    The lowest franchise buy-ins in the industry, with as little
                    as $1,000 down
                  </li>
                  <li>Guaranteed customer accounts</li>
                  <li>
                    Sixteen franchise options to fit your needs, from
                    owner-operator to a large franchise
                  </li>
                  <li>Growth opportunity and support</li>
                  <li>
                    Includes all memberships, training, sales and marketing
                    support, billing and collections, insurance, and branded
                    Stratus Green Clean products to build your own business
                  </li>
                  <li>No experience necessary, all training provided</li>
                  <li>State-of-the-Art equipment and materials</li>
                  <li>Large Customer Base</li>
                  <li>Leading Company in Green Clean Services</li>
                </ul>
                <p>
                  Stratus has affiliations with associations such as
                  International Franchise Association, VetFran, ISSA and
                  Entrepreneur Magazine!
                </p>
              </div>
            </div>
          </div>

          <p className="text-4xl">
            <span className="text-cyan-500">Investment</span> Levels
          </p>

          <div className="flex flex-wrap lg:flex-nowrap mt-5">
            <div className="my-5 w-full">
              <div className="py-5 px-10 text-center bg-cyan-700 text-white text-lg">
                <p className="text-2xl">Individual Level</p>
                <p className="font-bold  text-3xl">$</p>
              </div>

              <p className="p-2 border">Lowest Investment</p>
              <p className="p-2 border">Regional Based Support</p>
              <p className="p-2 border">Growth Opportunity</p>
              <p className="p-2 border">Owner-Operator Level</p>
            </div>

            <div className="border border-cyan-800  w-full">
              <div className="py-5 px-10 text-center bg-cyan-700 text-white text-lg">
                <p className="text-2xl">Management Level</p>
                <p>Most Popular</p>
                <p className="font-bold  text-3xl">$$</p>
              </div>

              <p className="p-2 border">Lowest Investment</p>
              <p className="p-2 border">Regional Based Support</p>
              <p className="p-2 border">Growth Opportunity</p>
              <p className="p-2 border">Owner-Operated + Employees</p>
              <button className="bg-cyan-600  p-3 text-white text-sm rounded-md text-center m-4">
                Find A Location
              </button>
            </div>

            <div className="mt-5   w-full">
              <div className="py-5 px-10 text-center bg-cyan-700 text-white text-lg">
                <p className="text-2xl">Executive Level</p>
                <p className="font-bold  text-3xl">$$$</p>
              </div>

              <p className="p-2 border">Lowest Investment</p>
              <p className="p-2 border">Regional Based Support</p>
              <p className="p-2 border">Large Scale Venture</p>
              <p className="p-2 border">Management + Multiple Employees</p>
            </div>
          </div>

          <div className="container mx-auto py-8">
            <div className="flex space-x-10">
              <div className="space-y-4 w-full">
                <hr className="border-gray-300" />
                <div className="flex flex-wrap items-center justify-items-end">
                  <div className="w-full lg:w-3/4">
                    <p className="font-light text-center py-4">
                      <span className="text-3xl">
                        Find Your Local Franchising Office
                      </span>{" "}
                      <br />
                      <span className="text-2xl">
                        Request More Information <b>Today!</b>
                      </span>
                    </p>
                  </div>
                  <div className="w-full lg:w-1/4 flex justify-center">
                    <button className="bg-cyan-600  p-3 text-white text-lg rounded-md text-center">
                      Find A Location
                    </button>
                  </div>
                </div>

                <hr className="border-gray-300" />
              </div>
            </div>
          </div>

          <section>
            <div className="container mx-auto py-8">
              <div className="flex flex-wrap lg:flex-nowrap lg:space-x-6">
                <div className="w-full lg:w-2/3 space-y-4 mb-5">
                  <p className="text-4xl">
                    How Do I Get <span className="text-cyan-500">Started</span>
                  </p>

                  <p>
                    If you are interested in a Stratus Franchise, please contact
                    the closest Regional Office. Our regional offices are
                    responsible for all Unit Franchise sales, training and
                    support in the territory. Contact your closest office today
                    to see if franchise opportunities are available!
                  </p>

                  <p>Availabilities in areas nationwide– inquire today!</p>
                </div>
                <div className="w-full lg:w-3/4">
                  <img src={img3} />
                </div>
              </div>
            </div>

            <div className="flex  flex-wrap lg:flex-nowrap space-x-4  py-5">
              <div className="flex flex-col justify-center items-center space-y-2">
                <img src="https://www.stratusclean.com/sites/default/files/styles/max_1300x1300/public/inline-images/Stratus%20-%203%20Franchise%20Relationships-01.png.webp?itok=63tbFG5f" />
              </div>

              <div className="flex flex-col justify-center items-center space-y-2">
                <img src="https://www.stratusclean.com/sites/default/files/styles/max_1300x1300/public/inline-images/Stratus%20-%203%20Franchise%20Relationships-02.png.webp?itok=oz_oPTgi" />
              </div>

              <div className="flex flex-col justify-center items-center space-y-2">
                <img src="https://www.stratusclean.com/sites/default/files/styles/max_1300x1300/public/inline-images/Stratus%20-%203%20Franchise%20Relationships-03.png.webp?itok=fVI12lzv" />
              </div>
            </div>
          </section>
        </section>

        <div className="lg: w-1/4"></div>
      </div>

      <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <a
              href="https://flowbite.com/"
              className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
            >
              <img src={logo} className="h-16" alt="Flowbite Logo" />
            </a>
            <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline me-4 md:me-6">
                  Licensing
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="https://flowbite.com/" className="hover:underline">
              1UnitedEnterprise
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
}

export default App;
